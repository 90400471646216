import axios from "axios";
import {
  getGitHubAPIURLIssuebyNumber,
  getGitHubAPIURLIssueFilterd,
  getGitHubAPIURLLabel,
  getGitHubAPIURLCollaborators,
  getGitHubURLIssuebyNumber,
} from "./GitHubURLHelper";
import {
  generateIssueFromGitHub,
  generateLinkFromGitHub,
  Arrangegantt,
  contentcheck,
} from "./GitHubHelper";
import { date2string } from "../Common/CommonHelper";
import { getYamlPartFromDescription, removeFirstSharp } from "../Common/Parser";
import {
  Assignee,
  GanttMessage,
  Issue,
  IssueInfo,
  IssueInfoAssignee,
  Label,
} from "../../components/Gantt/Gantt";

export const generateGitHubLinkFromIssue = (
  gitUrl: string,
  token: string,
  issueInfo: IssueInfo
): Issue | null => {
  if (issueInfo == null) {
    return null;
  }

  let links = [];
  const issue = generateIssueFromGitHub(issueInfo.body, issueInfo);
  const link = generateLinkFromGitHub(issueInfo);
  if (link != null) {
    for (let i = 0; i < link.length; i++) {
      let prelink = {
        type: link[i].type,
        target: link[i].target,
        source: link[i].source,
      };
      links.push(prelink);
    }
  }
  issue.links = links;
  return issue;
};

export const getGitHubIssuesFromAPI = async (
  gitUrl: string,
  token: string,
  selectedLabels: Label[],
  selectedAssignee: Assignee | null
): Promise<Issue[]> => {
  const url = getGitHubAPIURLIssueFilterd(
    gitUrl,
    selectedLabels,
    selectedAssignee
  );

  if (url == null) {
    throw new Error("Request URL is null.");
  }

  const res = await axios.get(url, {
    headers: { Authorization: `token ${token}` },
    data: {},
  });

  const issues: Issue[] = (res.data as any[])
    .map((issueInfo: any) =>
      generateGitHubLinkFromIssue(gitUrl, token, issueInfo)
    )
    .filter((issue) => issue != null && issue.description != null)
    .map((issue) => issue as Issue);

  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  for (const issue of issues) {
    if (issue.end_date == null) {
      issue.start_date = start;
      issue.end_date = end;
      issue.duration = 1;
    }
  }

  // end_dateに1日加算する
  for (const issue of issues) {
    if (issue.end_date != null) {
      issue.end_date.setDate(issue.end_date.getDate() + 1);
      issue.duration += 1;
    }
  }

  return issues;
};

export const setGitHubLabelListOfRepoFromAPI = async (
  gitUrl: string,
  token: string
): Promise<Label[]> => {
  const url = getGitHubAPIURLLabel(gitUrl);

  if (url == null) {
    throw new Error("Request URL is null.");
  }

  const res = await axios.get(url, {
    headers: { Authorization: `token ${token}` },
    data: {},
  });

  let labels: Label[] = [];
  res.data.map((info: any) => {
    labels.push({ id: info.id, name: info.name });
    return null;
  });
  return labels;
};

export const setGitHubMemberListOfRepoFromAPI = async (
  gitUrl: string,
  token: string
): Promise<Assignee[]> => {
  const url = getGitHubAPIURLCollaborators(gitUrl);

  if (url == null) {
    throw new Error("Request URL is null.");
  }

  const res = await axios.get(url, {
    headers: { Authorization: `token ${token}` },
    data: {},
  });

  let members: Assignee[] = [];
  res.data.map((info: any) => {
    members.push({ id: info.id, name: info.login });
    return null;
  });
  return members;
};

export const updateGitHubIssueFromIssue = async (
  issue: Issue,
  token: string,
  gitUrl: string
): Promise<GanttMessage> => {
  const issueInfo = await getGitHubIssuebyNumber(gitUrl, issue.id, token);

  if (issueInfo == null) {
    return {
      text: "failed get GitHub issue. check your url.",
      type: "error",
    };
  }

  if (
    getUpdateIssueText(
      issueInfo.body,
      issue.start_date,
      issue.end_date,
      issue.cost
    ) == null
  ) {
    return {
      text: "failed update issue. " + issue.text,
      type: "error",
    };
  }

  // end_dateから1日減算する
  if (issue.end_date != null) {
    issue.end_date.setDate(issue.end_date.getDate() - 1);
  }

  if (Number(issue.start_date) >= Number(issue.end_date)) {
    return {
      text: "期間が不正です",
      type: "error",
    };
  }

  if (
    contentcheck(
      Arrangegantt(issue),
      generateIssueFromGitHub(issueInfo.body, issueInfo),
      generateLinkFromGitHub(issueInfo)
    ) !== true
  ) {
    const message = await updateGitHubIssue(gitUrl, issueInfo, issue, token);

    if (issue.end_date != null) {
      issue.end_date.setDate(issue.end_date.getDate() + 1);
    }

    return message;
  }

  return {
    text: "エラーが発生しました",
    type: "error",
  };
};

const getGitHubIssuebyNumber = async (
  gitUrl: string,
  id: string,
  token: string
): Promise<IssueInfo> => {
  const url = getGitHubAPIURLIssuebyNumber(gitUrl, removeFirstSharp(id));

  if (url == null) {
    throw new Error("Request URL is null.");
  }

  const res = await axios.get(url, {
    headers: { Authorization: `token ${token}` },
    data: {},
  });

  const assignee: IssueInfoAssignee | null = (() => {
    if (res.data.assignee != null) {
      return {
        id: res.data.assignee.id,
        login: res.data.assignee.login,
      };
    }
    return null;
  })();

  return {
    id: res.data.id,
    title: res.data.title,
    body: res.data.body,
    state: res.data.state,
    assignee: assignee,
    createdAt: res.data.created_at,
    updatedAt: res.data.updated_at,
    labels: (res.data.labels as any[]).map((label) => {
      return {
        id: label.id,
        name: label.name,
      };
    }),
    number: res.data.number,
  };
};

export const updateGitHubIssue = async (
  gitUrl: string,
  issueInfo: IssueInfo,
  issue: Issue,
  token: string
): Promise<GanttMessage> => {
  const url = getGitHubAPIURLIssuebyNumber(gitUrl, removeFirstSharp(issue.id));

  if (url == null) {
    throw new Error("Request URL is null.");
  }

  await axios.post(
    url,
    {
      body: getUpdateIssueText(
        issueInfo.body,
        issue.start_date,
        issue.end_date,
        issue.cost
      ),
    },
    {
      headers: {
        Authorization: `token ${token}`,
      },
    }
  );

  return {
    text: "success update issue.  " + issue.id,
    type: "success",
  };
};

const getUpdateIssueText = (
  description: string,
  startDate: Date | null,
  endDate: Date | null,
  cost: number | null
): string => {
  let text = description;

  const newStartDate = date2string(startDate);
  const newEndDate = date2string(endDate);
  const newCost = cost;

  // YAMLの部分のテキストを抽出する
  const yamlPart = getYamlPartFromDescription(text);

  // 更新後のYAMLを生成する
  let newText = "";
  if (newStartDate != null) {
    newText += `
# 開始日
start_date: ${newStartDate}
`;
  }

  if (newEndDate != null) {
    newText += `
# 終了日
due_date: ${newEndDate}
`;
  }

  if (newCost != null) {
    newText += `
# 工数（人日）
cost: ${newCost}
`;
  }

  if (yamlPart != null) {
    text = text.replace(yamlPart, newText);
  } else {
    text =
      `
\`\`\`yaml
${newText}
\`\`\`

` + text;
  }

  return text;
};

export const openGitHubIssueAtBrowser = (
  issueId: string,
  gitUrl: string
): void => {
  const url = getGitHubURLIssuebyNumber(gitUrl, removeFirstSharp(issueId));

  if (url == null) {
    throw new Error("Request URL is null.");
  }

  window.open(url, "_blank");
};
