import { Assignee, Label } from "../../components/Gantt/Gantt";
import {
  isValidVariable,
  isValidIDName,
  isValidURL,
  isNumber,
} from "../Common/CommonHelper";
import { removeFirstSharp } from "../Common/Parser";

const GitHubAPIURL = "https://api.github.com/repos/";
const GitHubURL = "https://github.com/";

export const isGitHubURL = (gitUrl: string): boolean => {
  if (!isValidURL(gitUrl)) {
    return false;
  }
  if (gitUrl.split("/").length < 5) {
    return false;
  }
  return /github\.com/.test(gitUrl);
};

export const getGitHubNameSpaceFromGitURL = (gitUrl: string): string | null => {
  if (!isGitHubURL(gitUrl)) {
    return null;
  }
  const splitGitUrl = gitUrl.split("/");
  if (splitGitUrl.length >= 5) {
    return splitGitUrl[3];
  }
  return null;
};

export const getGitHubProjectFromGitURL = (gitUrl: string): string | null => {
  if (!isGitHubURL(gitUrl)) {
    return null;
  }
  const splitGitUrl = gitUrl.split("/");
  if (splitGitUrl.length >= 5) {
    return splitGitUrl[4];
  }
  return null;
};

export const getGitHubAPIURLIssue = (gitUrl: string): string | null => {
  if (!isGitHubURL(gitUrl)) {
    return null;
  }
  return (
    GitHubAPIURL +
    getGitHubNameSpaceFromGitURL(gitUrl) +
    "/" +
    getGitHubProjectFromGitURL(gitUrl) +
    "/issues"
  );
};

export const getGitHubAPIURLIssuebyNumber = (
  gitUrl: string,
  number: string
): string | null => {
  if (!isGitHubURL(gitUrl) || !isValidVariable(number)) {
    return null;
  }
  return (
    GitHubAPIURL +
    getGitHubNameSpaceFromGitURL(gitUrl) +
    "/" +
    getGitHubProjectFromGitURL(gitUrl) +
    "/issues/" +
    number
  );
};

export const getGitHubAPIURLIssueFilterd = (
  gitUrl: string,
  labels: Label[],
  assignee: Assignee | null
): string | null => {
  if (!isGitHubURL(gitUrl)) {
    return null;
  }

  const params = new URLSearchParams("");

  if (isValidVariable(labels) && labels.length > 0) {
    params.set("labels", labels.map((label) => label.name).join());
  }

  if (assignee != null && isValidVariable(assignee)) {
    if (isValidIDName(assignee)) {
      if (assignee.name !== "") {
        params.set("assignee", assignee.name);
      }
    }
  }

  params.set("state", "all");

  return (
    GitHubAPIURL +
    getGitHubNameSpaceFromGitURL(gitUrl) +
    "/" +
    getGitHubProjectFromGitURL(gitUrl) +
    "/issues?" +
    params.toString()
  );
};

export const getGitHubAPIURLLabel = (gitUrl: string): string | null => {
  if (!isGitHubURL(gitUrl)) {
    return null;
  }
  return (
    GitHubAPIURL +
    getGitHubNameSpaceFromGitURL(gitUrl) +
    "/" +
    getGitHubProjectFromGitURL(gitUrl) +
    "/labels"
  );
};

export const getGitHubAPIURLCollaborators = (gitUrl: string): string | null => {
  if (!isGitHubURL(gitUrl)) {
    return null;
  }
  return (
    GitHubAPIURL +
    getGitHubNameSpaceFromGitURL(gitUrl) +
    "/" +
    getGitHubProjectFromGitURL(gitUrl) +
    "/collaborators"
  );
};

export const getGitHubURLIssuebyNumber = (gitUrl: string, number: string) => {
  if (!isGitHubURL(gitUrl) || !isValidVariable(number)) {
    return null;
  }
  if (!isNumber(number)) {
    number = removeFirstSharp(number);
  }
  if (Number(number) <= 0) {
    return null;
  }
  return (
    GitHubURL +
    getGitHubNameSpaceFromGitURL(gitUrl) +
    "/" +
    getGitHubProjectFromGitURL(gitUrl) +
    "/issues/" +
    number
  );
};
