import { GanttStatic } from "dhtmlx-gantt";
import moment from "moment";
import { IdAndName, Issue } from "../../components/Gantt/Gantt";

export const isValidVariable = (variable: any): boolean => {
  if (
    variable !== null &&
    variable !== [] &&
    variable !== void 0 &&
    variable !== ""
  ) {
    return true;
  }
  if (Array.isArray(variable)) {
    return variable.length > 0;
  }
  return false;
};

export const isValidIDName = (idAndName: IdAndName): boolean => {
  return isValidVariable(idAndName) && "id" in idAndName && "name" in idAndName;
};

export const isValidURL = (url: string): boolean => {
  if (!isValidVariable(url)) {
    return false;
  }
  return /https?:\/\//.test(url);
};

export const isNumber = (n: any) => {
  if (typeof n === "number" && Number.isFinite(n)) {
    return true;
  }
  return false;
};

export const calculateDuration = (
  startDate: Date | null,
  endDate: Date | null
): number => {
  if (startDate == null || endDate == null) {
    return 0;
  }
  const start_date_moment = moment(startDate, "YYYY/MM/DD");
  const end_date_moment = moment(endDate, "YYYY/MM/DD");
  return end_date_moment.diff(start_date_moment, "days") + 1;
};

export const calculateEndDate = (
  startDateStr: string,
  duration: number
): string | null => {
  const startDate = new Date(startDateStr);
  const endDate = moment(startDate, "YYYY/MM/DD")
    .add(duration - 1, "d")
    .toDate();
  return date2string(endDate);
};

export const date2string = (date: Date | null): string | null => {
  if (typeof date === "string") {
    date = new Date(date);
  }

  if (date == null || !(date instanceof Date) || isNaN(date.getFullYear())) {
    return null;
  }

  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}/${month}/${day}`;
};

export const string2date = (text: string | null): Date | null => {
  if (text != null) {
    return new Date(text);
  }
  return null;
};

export const adjustDateString = (dateStr: string | null): string => {
  if (dateStr != null) {
    return date2string(new Date(dateStr)) ?? "";
  }
  return "";
};

export const getGanttUpdateDate = (
  createdAt: string | null,
  updatedAt: string | null
): string => {
  let updatedDateStr = null;
  if (updatedAt != null) {
    updatedDateStr = adjustDateString(updatedAt);
  } else {
    updatedDateStr = adjustDateString(createdAt);
  }
  return updatedDateStr;
};

export const displayCostMessage = (gantt: GanttStatic, issues: Issue[]) => {
  gantt.message(
    `合計工数: ${issues
      .map((issue) => issue.cost)
      .reduce((sum, cost) => Number(sum) + Number(cost))}`
  );
};
