import {
  convertIDNameListToString,
  removeLastSlash,
  removeLastSpace,
} from "../functions/Common/Parser";
import { openIssueAtBrowser } from "../functions/Common/IssueAPI";
import { isGitHubURL } from "../functions/GitHub/GitHubURLHelper";

import { gantt } from "dhtmlx-gantt";
import { Assignee, Issue, Label } from "../components/Gantt/Gantt";

export type AppState = {
  currentZoom: string;
  update: number;
  git_url: string;
  token: string;
  labels: Label[];
  selected_labels: Label[];
  member_list: Assignee[];
  selected_assignee: Assignee | null;
  issue: Issue[];
};

export const initialState: AppState = {
  currentZoom: "Days",
  update: 0,
  git_url: "",
  token: "Tokens that have not yet been entered",
  labels: [],
  selected_labels: [],
  member_list: [],
  selected_assignee: null,
  issue: [],
};

export const handleOpenIssueAtBrowser = (
  issueId: string | null,
  gitUrl: string | null
) => {
  if (issueId != null && gitUrl != null) {
    openIssueAtBrowser(issueId, gitUrl);
  }
};

export const handleGitURLChange = (
  git_url: string,
  selected_labels: Label[],
  selected_assignee: Assignee | null
) => {
  git_url = removeLastSlash(removeLastSpace(git_url));
  if (isGitHubURL(git_url)) {
    gantt.message({ text: "Access GitHub.com" });
  } else if (git_url === "") {
  } else {
    gantt.message({ text: "Not a valid URL.", type: "error" });
    return null;
  }
  setURLQuery(git_url, selected_labels, selected_assignee);
  return git_url;
};

export const handleSelectedLabelsChange = (
  git_url: string,
  selected_labels: Label[],
  selected_assignee: Assignee | null
) => {
  setURLQuery(git_url, selected_labels, selected_assignee);
  return selected_labels;
};

export const handleSelectedAssigneeChange = (
  git_url: string,
  selected_labels: Label[],
  selected_assignee: Assignee | null
) => {
  setURLQuery(git_url, selected_labels, selected_assignee);
  return selected_assignee;
};

export const setURLQuery = (
  git_url: string,
  selected_labels: Label[],
  selected_assignee: Assignee | null
) => {
  const params = new URLSearchParams(window.location.search);
  params.set("giturl", git_url);
  params.set("labels", convertIDNameListToString(selected_labels) ?? "");
  params.set("assignee", convertIDNameListToString([selected_assignee]) ?? "");
  window.history.pushState(null, "", `?${params.toString()}`);
  return null;
};
