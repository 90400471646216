import React, { useEffect, useRef } from "react";
import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import { attachEvent } from "./GanttAttachEvent";
import { setGanttTemplates } from "./GanttTemplates";
import { setGanttConfig } from "./GanttConfig";

export type IdAndName = {
  id: number;
  name: string;
};

export type Label = {
  id: number;
  name: string;
};

export type Assignee = {
  id: number;
  name: string;
};

export type Issue = {
  id: string;
  text: string;
  start_date: Date | null;
  end_date: Date | null;
  duration: number;
  progress: number;
  state: "open" | "closed";
  assignee: Assignee | null;
  parent: number;
  description: string;
  cost: number;
  update: Date | null;
  links: any[];
};

// TODO idはstringにキャスト
export type IssueInfoAssignee = {
  id: string;
  login: string;
};

// TODO idはstringにキャスト
export type IssueInfo = {
  id: string;
  title: string;
  body: string;
  state: "open" | "closed";
  assignee: IssueInfoAssignee | null;
  createdAt: string;
  updatedAt: string;
  labels: Label[];
  number: number;
};

export type GanttProps = {
  zoom: string;
  gitUrl: string;
  token: string;
  selectedLabels: Label[];
  selectedAssignee: Assignee | null;
  issues: Issue[];
  update: number;
  openIssueAtBrowser: (ganttTaskId: string) => void;
  updateIssueByAPI: (ganttTask: Issue) => void;
};

export type GanttMessage = {
  text: string;
  type: "success" | "error";
};

const Gantt: React.VFC<GanttProps> = (props) => {
  const containerRef = useRef(null);
  useEffect(() => {
    setGanttConfig(gantt);
    setGanttTemplates(gantt);
    attachEvent(gantt, props);
    if (containerRef.current != null) {
      gantt.init(containerRef.current);
      gantt.ext.zoom.setLevel(props.zoom);
    }
  });

  useEffect(() => {
    if (props.zoom === "Days") {
      gantt.eachTask(function (task) {
        task.$open = true;
      });
    } else {
      gantt.eachTask(function (task) {
        task.$open = false;
      });
    }
    gantt.ext.zoom.setLevel(props.zoom);
  }, [props.zoom]);

  useEffect(() => {
    try {
      if (props.issues != null) {
        gantt.clearAll();
        props.issues.forEach((issue) => {
          gantt.addTask(issue);
          if ("links" in issue) {
            issue.links.map((link) => {
              console.log(link);
              gantt.addLink(link);
              return null;
            });
          }
        });
        gantt.sort("end_date", false);
      }
    } catch (err) {
      gantt.message({ text: err, type: "error" });
    }
  }, [props.issues]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "100%" }}></div>
  );
};

export default Gantt;
