import "dhtmlx-gantt";
import { GanttStatic } from "dhtmlx-gantt";
import { Issue } from "./Gantt";

const zoomLevel = {
  levels: [
    {
      name: "Days",
      scale_height: 30,
      min_column_width: 25,
      scales: [
        { unit: "month", step: 1, format: "%n" },
        { unit: "day", step: 1, format: "%d" },
      ],
    },
    {
      name: "Weeks",
      scale_height: 30,
      min_column_width: 70,
      scales: [{ unit: "week", step: 1, format: "%n/%d~" }],
    },
  ],
};

const shortenDate = (date: Date | null) => {
  if (
    date == null ||
    Object.prototype.toString.call(date) !== "[object Date]"
  ) {
    return null;
  }
  const m = ("00" + (date.getMonth() + 1)).slice(-2);
  const d = ("00" + date.getDate()).slice(-2);
  const shorten_date = m + "/" + d;
  return shorten_date;
};

export const setGanttConfig = (gantt: GanttStatic) => {
  gantt.config.xml_date = "%Y/%m/%d %H:%i";
  gantt.config.order_branch = false;
  gantt.config.order_branch_free = false;

  gantt.config.keep_grid_width = true;
  gantt.config.grid_resize = true;
  gantt.config.open_tree_initially = true;
  gantt.config.fit_tasks = true;
  gantt.config.sort = true;

  // ドラッグで子タスクを作成できないようにする
  gantt.config.drag_links = false;
  gantt.config.drag_progress = false;

  gantt.config.columns = [
    {
      name: "id",
      label: "No.",
      align: "left",
      tree: true,
      width: "90",
      template: (obj: any) => {
        var befweek = new Date();
        befweek.setDate(befweek.getDate() - 7);
        if (obj.update < befweek.toLocaleDateString()) {
          return (
            obj.id +
            "<a title='There is no update for a week.'><span class='overdue'>i</span></a>"
          );
        }
        return obj.id;
      },
    },
    {
      name: "start_date",
      label: "Start ",
      align: "center",
      width: "60",
      template: (obj: any) => {
        return shortenDate(obj.start_date);
      },
    },
    {
      name: "end_date",
      label: "Due ",
      align: "center",
      width: "60",
      template: (issue: Issue) => {
        return shortenDate(
          ((): Date | null => {
            if (issue.end_date != null) {
              const endDate = new Date(
                issue.end_date.getFullYear(),
                issue.end_date.getMonth(),
                issue.end_date.getDate()
              );
              endDate.setDate(endDate.getDate() - 1);
              return endDate;
            }
            return null;
          })()
        );
      },
    },
    {
      name: "assignee",
      label: "Assignee",
      align: "center",
      width: "100",
      template: (issue: Issue) => {
        return issue.assignee?.name;
      },
    },
    {
      name: "cost",
      label: "Cost",
      align: "center",
      width: "90",
      editor: {
        type: "number",
        map_to: "cost",
      },
    },
  ];

  gantt.plugins({
    // quick_info: true,
    drag_timeline: true,
  });
  gantt.showDate(new Date());
  gantt.ext.zoom.init(zoomLevel);
};
