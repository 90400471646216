import { GanttStatic } from "dhtmlx-gantt";
import { GanttProps } from "./Gantt";

export const attachEvent = (gantt: GanttStatic, props: GanttProps) => {
  gantt.attachEvent(
    "onTaskDblClick",
    (gantt_task_id, e) => {
      props.openIssueAtBrowser(gantt_task_id);
    },
    {}
  );

  gantt.attachEvent(
    "onAfterTaskUpdate",
    (id, issue) => {
      props.updateIssueByAPI(issue);
    },
    {}
  );

  gantt.attachEvent("onBeforeTaskUpdate", (id, mode, gantt_task) => {}, {});

  gantt.attachEvent("onAfterTaskMove", (id, parent) => {}, {});
  gantt.attachEvent(
    "onAfterLinkAdd",
    function (id, item) {
      let afterlinkId: any[] = [];
      let afterlink: any[] = [];
      let addobj = item.target;
      let taskObj = gantt.getTask(addobj);
      let target = taskObj.$target;
      target.forEach((linkId: any) => {
        let link = gantt.getLink(linkId);
        let linkid = link.target;
        let linkIds = link.source;
        afterlink.push({ type: "0", target: linkid, source: linkIds });
        let relinkIds = linkIds.slice(1);
        if (relinkIds !== "") {
          afterlinkId.push(relinkIds);
        }
      });
      // let linkids = afterlinkId.join(',');
      // gantt.getTask(addobj).dependon = linkids; //changes task's data
      // gantt.updateTask(addobj); //renders the updated task
      gantt.getTask(addobj).dependon = afterlinkId;
      gantt.getTask(addobj).links = afterlink;
      // @ts-ignore
      gantt.updateTask(addobj);
    },
    {}
  );

  gantt.attachEvent(
    "onAfterLinkDelete",
    function (id, item) {
      let afterlinkId: any[] = [];
      let afterlink: any[] = [];
      let addobj = item.target;
      let taskObj = gantt.getTask(addobj);
      let target = taskObj.$target;
      target.forEach((linkId: any) => {
        let link = gantt.getLink(linkId);
        let linkid = link.target;
        let linkIds = link.source;
        afterlink.push({ type: "0", target: linkid, source: linkIds });
        let relinkIds = linkIds.slice(1);
        if (relinkIds !== "") {
          afterlinkId.push(relinkIds);
        }
      });
      // let linkids = afterlinkId.join(',');
      // gantt.getTask(addobj).dependon = linkids; //changes task's data
      // gantt.updateTask(addobj); //renders the updated task
      gantt.getTask(addobj).dependon = afterlinkId;
      gantt.getTask(addobj).links = afterlink;
      // @ts-ignore
      gantt.updateTask(addobj);
    },
    {}
  );

  // Changing the displayed range dynamically
  // https://docs.dhtmlx.com/gantt/desktop__configuring_time_scale.html#range
  gantt.attachEvent(
    "onTaskDrag",
    function (id, mode, task, original) {
      var state = gantt.getState();
      var minDate = state.min_date,
        maxDate = state.max_date;

      var scaleStep =
        // @ts-ignore
        gantt.date.add(new Date(), state.scale_step, state.scale_unit) -
        // @ts-ignore
        new Date();

      var showDate,
        repaint = false;
      if (mode === "resize" || mode === "move") {
        if (Math.abs(task.start_date - minDate) < scaleStep) {
          showDate = task.start_date;
          repaint = true;
        } else if (Math.abs(task.end_date - maxDate) < scaleStep) {
          showDate = task.end_date;
          repaint = true;
        }

        if (repaint) {
          gantt.render();
          gantt.showDate(showDate);
        }
      }
    },
    {}
  );
};
