import {
  getNumberFromDescriptionYaml,
  getDependonFromDescriptionYaml,
  getStringFromDescriptionYaml,
} from "../Common/Parser";
import {
  calculateDuration,
  getGanttUpdateDate,
  string2date,
} from "../Common/CommonHelper";
import { Assignee, Issue, IssueInfo } from "../../components/Gantt/Gantt";

const getGitHubAssignee = (issueInfo: IssueInfo): Assignee | null => {
  if (issueInfo.assignee != null) {
    return {
      id: Number(issueInfo.assignee.id),
      name: issueInfo.assignee.login,
    };
  }
  return null;
};

export const generateIssueFromGitHub = (
  description: string,
  issueInfo: IssueInfo
): Issue => {
  return {
    id: "#" + issueInfo.number,
    text: issueInfo.title,
    start_date: string2date(
      getStringFromDescriptionYaml(description, "start_date")
    ),
    end_date: string2date(
      getStringFromDescriptionYaml(description, "due_date")
    ),
    duration: getDuration(description),
    progress: getNumberFromDescriptionYaml(description, "progress") ?? 0,
    state: issueInfo.state,
    assignee: getGitHubAssignee(issueInfo),
    parent: getNumberFromDescriptionYaml(description, "parent") ?? 0,
    description: description,
    cost: getNumberFromDescriptionYaml(description, "cost") ?? 0,
    update: string2date(
      getGanttUpdateDate(issueInfo.createdAt, issueInfo.updatedAt)
    ),
    links: [],
  };
};

export const getDuration = (description: string) => {
  const startDate = string2date(
    getStringFromDescriptionYaml(description, "start_date")
  );
  const endDate = string2date(
    getStringFromDescriptionYaml(description, "due_date")
  );
  return calculateDuration(startDate, endDate);
};

export const generateLinkFromGitHub = (issueInfo: IssueInfo): any[] => {
  const link: any[] = [];
  let dependon = [];
  dependon = getDependonFromDescriptionYaml(issueInfo.body, "dependon");
  if (dependon != null) {
    //let data = [];
    // TODO Link用のtypeを定義する
    // for (let i = 0; i < dependon.length; i++) {
    //   let data = [];
    //   data.type = "0";
    //   data.target = "#" + issueInfo.number;
    //   data.source = "#" + dependon[i];
    //   link.push(data);
    // }
    return link;
  }
  return [];
};

export const Arrangegantt = (issue: Issue): Issue => {
  // let arrangelink: any[] = [];
  // issue.links.map((list) => {
  //   let prearrangelink = [];
  //   prearrangelink.type = list.type;
  //   prearrangelink.target = list.target;
  //   prearrangelink.source = list.source;
  //   arrangelink.push(prearrangelink);
  // });

  return issue;
};

export const contentcheck = (issue0: Issue, issue1: Issue, links: any[]) => {
  if (
    issue0.id === issue1.id &&
    issue0.text === issue1.text &&
    issue0.start_date === issue1.start_date &&
    issue0.end_date === issue1.end_date &&
    issue0.duration === issue1.duration &&
    issue0.progress === issue1.progress &&
    issue0.assignee === issue1.assignee &&
    // issue0.description == issue1.description &&
    issue0.update === issue1.update &&
    issue0.parent === issue1.parent &&
    issue0.links.toString() === links.toString()
  ) {
    return true;
  } else {
    return false;
  }
};
