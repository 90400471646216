import { useState, useEffect } from "react";
import Toolbar from "./components/Toolbar";
import Gantt from "./components/Gantt";
import {
  bakeGitTokenCookie,
  readGitTokenCookie,
} from "./functions/Common/Cookie";
import { handleGitURLChange, handleOpenIssueAtBrowser } from "./State/Reducer";
import {
  getIssuesFromAPI,
  setLabelListOfRepoFromAPI,
  setMemberListOfRepoFromAPI,
  updateIssueByAPI,
} from "./functions/Common/IssueAPI";
import { gantt } from "dhtmlx-gantt";
import { Assignee, Issue, Label } from "./components/Gantt/Gantt";
import { convertIDNamesStringToList } from "./functions/Common/Parser";
import {
  displayCostMessage,
  isValidVariable,
} from "./functions/Common/CommonHelper";

const App = () => {
  const params = new URLSearchParams(window.location.search);

  const [updatedGanttTask, setUpdatedGanttTask] = useState<Issue | null>(null);
  const [gitToken, setGitToken] = useState<string>(readGitTokenCookie());
  const [gitUrl, setGitUrl] = useState<string>(params.get("giturl") ?? "");
  const [members, setMembers] = useState<Assignee[]>([]);
  const [selectedAssignee, setSelectedAssignee] = useState<Assignee | null>(
    ((): Assignee | null => {
      const list = convertIDNamesStringToList(params.get("assignee") ?? "");
      if (list.length > 0) {
        return list[0];
      }
      return null;
    })()
  );
  const [labels, setLabels] = useState<Label[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<Label[]>(
    convertIDNamesStringToList(params.get("labels") ?? "")
  );
  const [issues, setIssues] = useState<Issue[]>([]);
  const [currentZoom, setCurrentZoom] = useState<string>("");
  const [updateCount, setUpdateCount] = useState<number>(0);

  // GitHubのAPI制限回避のため
  const [firstFetched, setFirstFetched] = useState<boolean>(false);

  useEffect(() => {
    bakeGitTokenCookie(gitToken);
  }, [gitToken]);

  useEffect(() => {
    handleGitURLChange(gitUrl, selectedLabels, selectedAssignee);
  }, [gitUrl, selectedLabels, selectedAssignee]);

  window.addEventListener("popstate", (e) => {
    const params = new URLSearchParams(window.location.search);

    setGitUrl(params.get("giturl") ?? "");

    const queryLabels = convertIDNamesStringToList(params.get("labels") ?? "");
    if (isValidVariable(queryLabels[0])) {
      if ("name" in queryLabels[0]) {
        if (queryLabels[0].name !== "") {
          setSelectedLabels(queryLabels);
        }
      }
    }

    const selectedAssigneeList = convertIDNamesStringToList(
      params.get("assignee") ?? ""
    );
    if (isValidVariable(selectedAssigneeList)) {
      setSelectedAssignee(selectedAssigneeList[0]);
    }
  });

  useEffect(() => {
    //dispatch({ type: 'getIssueByAPI' });
    (async () => {
      try {
        if (updatedGanttTask == null) {
          if (!firstFetched) {
            const [labels, members, issues] = await Promise.all([
              setLabelListOfRepoFromAPI(gitUrl, gitToken),
              setMemberListOfRepoFromAPI(gitUrl, gitToken),
              getIssuesFromAPI(
                gitUrl,
                gitToken,
                selectedLabels,
                selectedAssignee
              ),
            ]);
            setLabels(labels);
            setMembers(members);
            setIssues(issues ?? []);
            setFirstFetched(true);
            displayCostMessage(gantt, issues ?? []);
          } else {
            gantt.sort("end_date", false);
          }
        } else {
          if (updatedGanttTask != null) {
            const result = await updateIssueByAPI(
              updatedGanttTask,
              gitToken,
              gitUrl
            );
            gantt.message(result);
            displayCostMessage(gantt, issues);
          }
          setUpdatedGanttTask(null);
        }
      } catch (err) {
        gantt.message({ text: err, type: "error" });
      }
    })();
  }, [
    gitUrl,
    gitToken,
    selectedLabels,
    selectedAssignee,
    updateCount,
    updatedGanttTask,
    firstFetched,
    issues,
  ]);

  return (
    <>
      <div className="zoom-bar">
        <Toolbar
          zoom={currentZoom}
          onZoomChange={(zoom) => setCurrentZoom(zoom)}
          gitUrl={gitUrl}
          onGitURLChange={(gitUrl) => setGitUrl(gitUrl)}
          token={gitToken}
          onTokenChange={(token) => setGitToken(token)}
          onUpdateClick={() => setUpdateCount(updateCount + 1)}
          labels={labels}
          selectedLabels={selectedLabels}
          onSelectedLabelChange={(selectedLabels) =>
            setSelectedLabels(selectedLabels)
          }
          memberList={members}
          selectedAssignee={selectedAssignee}
          onSelectedAssigneeChange={(selectedAssignee) =>
            setSelectedAssignee(selectedAssignee)
          }
        />
      </div>
      <div className="gantt-container">
        <Gantt
          zoom={currentZoom}
          gitUrl={gitUrl}
          token={gitToken}
          selectedLabels={selectedLabels}
          selectedAssignee={selectedAssignee}
          issues={issues}
          update={updateCount}
          openIssueAtBrowser={(issueId) =>
            handleOpenIssueAtBrowser(issueId, gitUrl)
          }
          updateIssueByAPI={(issue) => setUpdatedGanttTask(issue)}
        />
      </div>
    </>
  );
};

export default App;
