import { isGitHubURL } from "../GitHub/GitHubURLHelper";
import {
  getGitHubIssuesFromAPI,
  updateGitHubIssueFromIssue,
  openGitHubIssueAtBrowser,
  setGitHubLabelListOfRepoFromAPI,
  setGitHubMemberListOfRepoFromAPI,
} from "../GitHub/GitHubAPI";
import { isValidURL } from "../Common/CommonHelper";
import { Assignee, Issue, Label } from "../../components/Gantt/Gantt.js";

export const isGitHubAccessible = (url: string): boolean => {
  return isValidURL(url) && isGitHubURL(url);
};

export const getIssuesFromAPI = async (
  gitUrl: string,
  token: string,
  selected_labels: Label[],
  selected_assignee: Assignee | null
) => {
  if (isGitHubAccessible(gitUrl)) {
    return await getGitHubIssuesFromAPI(
      gitUrl,
      token,
      selected_labels,
      selected_assignee
    );
  }
  return null;
};

export const setLabelListOfRepoFromAPI = async (
  gitUrl: string,
  token: string
): Promise<Label[]> => {
  if (isGitHubAccessible(gitUrl)) {
    return setGitHubLabelListOfRepoFromAPI(gitUrl, token);
  }
  return [];
};

export const setMemberListOfRepoFromAPI = async (
  gitUrl: string,
  token: string
): Promise<Assignee[]> => {
  if (isGitHubAccessible(gitUrl)) {
    return setGitHubMemberListOfRepoFromAPI(gitUrl, token);
  }
  return [];
};

export const updateIssueByAPI = async (
  issue: Issue,
  token: string,
  gitUrl: string
) => {
  if (isGitHubAccessible(gitUrl)) {
    return await updateGitHubIssueFromIssue(issue, token, gitUrl);
  }
  return null;
};

export const openIssueAtBrowser = (ganttTaskId: string, gitUrl: string) => {
  if (isGitHubAccessible(gitUrl)) {
    openGitHubIssueAtBrowser(ganttTaskId, gitUrl);
  }
};
