import "dhtmlx-gantt";
import { GanttStatic } from "dhtmlx-gantt";
import {
  calculateDuration,
  calculateEndDate,
  date2string,
} from "../../functions/Common/CommonHelper";
import { Issue } from "./Gantt";

export const setGanttTemplates = (gantt: GanttStatic) => {
  gantt.templates.timeline_cell_class = (item, date): string => {
    if (Object.prototype.toString.call(date) !== "[object Date]") {
      return "";
    }
    var today = new Date();
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth()
    ) {
      return "today";
    }
    if (date.getDay() === 0 || date.getDay() === 6) {
      return "weekend";
    }
    var yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    if (date < yesterday) {
      return "past_days";
    }
    return "";
  };

  gantt.templates.task_text = (start, end, task): string => {
    return task.text;
  };

  gantt.templates.task_class = (start, end, task: Issue): string => {
    if (task.progress === 1) {
      return "";
    }

    const startDateStr = date2string(start);
    if (startDateStr == null) {
      return "";
    }

    const endDateStr = calculateEndDate(
      startDateStr,
      (calculateDuration(start, end) + 1) * task.progress
    );
    if (endDateStr == null) {
      return "";
    }

    if (task.state === "closed") {
      return "finished";
    }

    if (task.progress < 0.01) {
      if (start <= new Date()) {
        return "behind";
      }
    } else if (new Date(endDateStr) < new Date()) {
      return "behind";
    }
    return "";
  };
};
