import { isValidVariable, isValidIDName } from "./CommonHelper";
import yaml from "js-yaml";
import { gantt } from "dhtmlx-gantt";

export const removeFirstSharp = (text: string): string => {
  if (text.length > 1 && /^#/.test(text)) {
    text = text.substring(1);
  }
  return text;
};

export const removeLastSlash = (text: string): string => {
  if (text.length > 1 && /\/$/.test(text)) {
    text = text.slice(0, -1);
  }
  return text;
};

export const removeLastSpace = (text: string): string => {
  if (text.length > 1 && / +$/.test(text)) {
    text = text.slice(0, -1);
  }
  return text;
};

export const getYamlPartFromDescription = (
  description: string | null
): string | null => {
  if (description == null) {
    return null;
  }

  const startIndex = description.indexOf("```yaml");
  const endIndex = description.indexOf("```", startIndex + 1);

  if (startIndex >= 0 && endIndex >= 0 && endIndex > startIndex) {
    // YAMLの範囲が存在する
    return description.substring(startIndex + 7, endIndex);
  } else {
    // YAMLの範囲が存在しない
    return null;
  }
};

export const parseYamlFromDescription = (
  description: string | null
): any | null => {
  const yamlPart = getYamlPartFromDescription(description);
  if (yamlPart == null) {
    return null;
  }

  try {
    return yaml.load(yamlPart);
  } catch (error) {
    gantt.message({ text: "failed load yaml" + yamlPart, type: "error" });
    return null;
  }
};

export const getNumberFromDescriptionYaml = (
  description: string,
  column_name: string
): number | null => {
  const yamlStruct = parseYamlFromDescription(description);
  if (yamlStruct == null || !(column_name in yamlStruct)) {
    return null;
  }
  const number = yamlStruct[column_name];
  if (typeof number !== "number") {
    return null;
  }
  return number;
};

export const getStringFromDescriptionYaml = (
  description: string,
  columnName: string
): string | null => {
  const yamlStruct = parseYamlFromDescription(description);
  if (yamlStruct == null || !(columnName in yamlStruct)) {
    return null;
  }
  const text = yamlStruct[columnName];
  if (typeof text !== "string") {
    return null;
  }
  return text;
};

export const convertIDNameListToString = (list: any[]): string | null => {
  if (isValidVariable(list)) {
    const resultList = list
      .filter((info) => isValidIDName(info) && isValidVariable(info.id))
      .map((info) => info.id + ":" + info.name + ",");
    return resultList.join("");
  }
  return null;
};

export const convertIDNamesStringToList = (string: string) => {
  let list: any[] = [];
  if (isValidVariable(string)) {
    const split_string = string.split(",");
    split_string.forEach((element, index, array) => {
      if (index < split_string.length - 1) {
        const info = element.split(":");
        if (!isNaN(parseInt(info[0]))) {
          const label = {
            id: parseInt(info[0]),
            name: info[1],
          };
          list.push(label);
        }
      }
    });
  } else {
    list = [];
  }
  return list;
};

export const getDependonFromDescriptionYaml = (
  description: string,
  column_name: string
): any => {
  const yamlStruct = parseYamlFromDescription(description);
  if (yamlStruct === null || !(column_name in yamlStruct)) {
    return null;
  }
  const number = yamlStruct[column_name];
  return number;
};
